import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { TransformDomain } from "../types";
import { ReclaimSku } from "../Users";
import {
  MembershipRole as MembershipRoleDto,
  PartialTeam as PartialTeamDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  ReclaimApi,
  RequestParams,
  TeamInvitation as TeamInvitationDto,
  TeamInvoice as TeamInvoiceDto,
  TeamMemberView as TeamMemberDto,
  TeamView as TeamDto,
} from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type TeamInvoice = Override<TeamInvoiceDto, {}>;
export const STRIPE_SESSION_URI = `${API_BASE_URI}/team/current/subscription/session`;
export const STRIPE_NEW_SESSION_URI = `${API_BASE_URI}/team/create/subscription/session`;

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export enum SubscriptionStatus {
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type Team = Override<
  TeamDto,
  {
    readonly id?: number;

    userInviteLevel: ReclaimSku;
    subscriptionStatus: SubscriptionStatus;
    paidProSeats: number;
    paidTeamSeats: number;
  }
>;

export type PartialTeam = Override<PartialTeamDto, {
  userInviteLevel?: ReclaimSku;
}>;

export type TeamMember = Override<
  TeamMemberDto,
  {
    readonly edition: ReclaimSku;
    readonly status: TeamMemberViewStatus;
    readonly membershipRole: MembershipRole;
  }
>;

export type PartialTeamInvitation = Override<
  PartialTeamInvitationDto,
  {
    role: MembershipRole;
  }
>;

export type TeamInvitation = TeamInvitationDto;

export enum SubscriptionFrequency {
  Month = "MONTH",
  Year = "YEAR",
}

export type DesiredSubscription = { frequency: SubscriptionFrequency; seats: number; sku: ReclaimSku };

export const dtoToTeam = (dto: TeamDto): Team => {
  return {
    ...dto,
    subscriptionStatus: dto.subscriptionStatus as unknown as SubscriptionStatus,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0,
    userInviteLevel: ReclaimSku.get(dto.userInviteLevel) || ReclaimSku.None,
  };
};

export const dtoToTeamMember = (dto: TeamMemberDto): TeamMember => {
  return {
    ...dto,
    edition: ReclaimSku.get(dto.edition) || ReclaimSku.Free,
    status: dto.status as unknown as TeamMemberViewStatus,
    membershipRole: dto.membershipRole as unknown as MembershipRole,
  };
};

export const dtoToTeamInvoice = (dto: TeamInvoiceDto): TeamInvoice => {
  return {
    ...dto,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto,
  }));
};

export class TeamDomain extends TransformDomain<Team, TeamDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberDto[]) => response.map(dtoToTeamMember));

  listInvoices = (): Promise<TeamInvoice[]> => this.domainApi.team.listInvoices().then((r) => r.map(dtoToTeamInvoice));

  deleteMember = (userId: string) => this.domainApi.team.deleteMember(userId);

  invite = (invites: PartialTeamInvitation[]): Promise<TeamInvitation[]> =>
    this.domainApi.team.createInvitation(invitesToDto(invites));

  patchTeam = (data: PartialTeam, params?: RequestParams) => this.domainApi.team.patchTeam(data, params);
  getInvitations = (): Promise<TeamInvitation[]> => this.domainApi.team.getInvitations();

  deleteInvitation = (id: string) => this.domainApi.team.deleteInvitation(id);

  startSubscription = (subscription: DesiredSubscription) => {
    const url = new URL(STRIPE_NEW_SESSION_URI, window.location.href);
    url.searchParams.append("frequency", subscription.frequency);
    url.searchParams.append("seats", subscription.seats.toString());
    url.searchParams.append("sku", subscription.sku.key);

    window.open(url.toString(), "billing_subscription");
  }
}
